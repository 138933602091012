<template>
  <ArchiveWrapper :title="title" :sections="sections" :card-actions="cardActions" type="files" />
</template>

<script>
import { computed } from 'vue'
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'

import useStructuresLocal from '@/composables/useStructures'
import useContentsLocal from '@/composables/useContents'
import useTranslations from '@/composables/useTranslations'
import { useAssets, useTenant, useStructures, useContents } from '@/core'
import useArchive from '@/composables/useArchive'
import ArchiveWrapper from './common/ArchiveWrapper'

export default {
  name: 'Files',

  components: {
    ArchiveWrapper
  },

  setup() {
    const { isFallbackLanguage, gqlContentQuery, getTenantTranslation } = useTranslations()
    const { locale } = useI18n()
    const { tenant } = useTenant()
    const { title, sections } = useArchive()
    const { fetchObject } = useContents()
    const { getObjectById } = useContentsLocal()
    const { fetchStructuresChildren } = useStructures()
    const { getStructuresChildrens } = useStructuresLocal()
    const { fetchAsset } = useAssets()
    const cardActions = {
      type: 'file',
      action: async item => {
        await fetchStructuresChildren(item.slugPath, {limit: 100, filter: `{"type": "OBJECT"}`})
        getStructuresChildrens(item.id, 'OBJECT').forEach(async sc => {
          if (sc.contentId) {
            if (isFallbackLanguage.value) {
              await fetchObject(sc.contentId)
            } else {
              await gqlContentQuery(sc.contentId)
            }
            const obj = getObjectById(sc.contentId, locale.value)
            fetchAsset(obj.content.assetId)
          }
        })
      }
    }

    useHead({
      title: computed(() => `${title.value} - ${getTenantTranslation(tenant.value.name)}`)
    })

    return {
      title,
      sections,
      cardActions
    }
  }
}
</script>
