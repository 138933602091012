<template>
  <div class="v-archive" :class="[`v-archive-${type}`]">
    <div class="v-archive__wrapper">
      <Breadcrumbs />
      <TheHeading class="v-archive__heading" level="h1">
        {{ title }}
      </TheHeading>
      <div v-if="sections" class="v-archive__sections">
        <section
          v-for="section in sections"
          :key="section.id"
          class="v-archive__section"
        >
          <TheHeading class="v-archive__heading">
            {{ section.name }}
          </TheHeading>
          <div class="v-archive__section-childrens">
            <div
              v-for="child in section.childrens"
              :key="child.id"
              class="v-archive__section-child"
            >
              <Card
                :title="child.name"
                :item="child"
                :description="child.description"
                :image-id="child.cover"
                :actions="cardActions"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import TheHeading from '@/components/TheHeading'
import Card from '@/components/Card'

export default {
  name: 'ArchiveWrapper',

  components: {
    Breadcrumbs,
    TheHeading,
    Card
  },

  props: {
    title: String,
    type: String,
    sections: Array,
    cardActions: Object
  }
}
</script>
