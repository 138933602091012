import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import useTranslations from '@/composables/useTranslations'
import useStructuresLocal from '@/composables/useStructures'

import { useStructures, useTenant, useBreadcrumbs, useLocalizations } from '@/core'

const useArchive = () => {
  const { locale } = useI18n()
  const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs()
  const { isFallbackLanguage } = useTranslations()
  const { tenant } = useTenant()
  const route = useRoute()
  const { getStructuresBySlugPath, fetchStructuresChildren, fetchStructuresNode } = useStructures()
  const { getStructuresChildrens } = useStructuresLocal()
  const parentSlug = route.params.parent
  const parentSlugPath = computed(() => {
    return `${tenant.value.slug}/${parentSlug}`
  })
  const parentStructure = computed(() => getStructuresBySlugPath(parentSlugPath.value, true, 'DIRECTORY', locale.value))
  const title = computed(() => parentStructure.value && parentStructure.value.name || '')
  const sections = computed(() => {
    return getStructuresChildrens(parentStructure.value.id, 'DIRECTORY', locale.value).map(child => {
      child.childrens = getStructuresChildrens(child.id, 'DIRECTORY', locale.value)

      return child
    })
  })
  const { fetchStructuresLocalization } = useLocalizations()

  onMounted(async () => {
    clearBreadcrumbs()
    await fetchStructuresNode(parentSlugPath.value)
    try {
      if (!isFallbackLanguage.value) {
        await fetchStructuresLocalization(parentStructure.value.id, locale.value)
      }
    } catch {
      //
    }
    await fetchStructuresChildren(parentSlugPath.value, {limit: 100, filter: `{"type": "DIRECTORY"}`})
    try {
      if (!isFallbackLanguage.value) {
        await Promise.all(sections.value.map(async section => {
          await fetchStructuresLocalization(section.id, locale.value)
        }))
      }
    } catch {
      //
    }
    setBreadcrumbs([
      {
        name: title.value
      }
    ])
    sections.value.forEach(async s => {
      await fetchStructuresChildren(`${parentSlugPath.value}/${s.slug}`, {limit: 100, filter: `{"type": "DIRECTORY"}`})
      if (!isFallbackLanguage.value) {
        getStructuresChildrens(s.id).forEach(sc => {
          fetchStructuresLocalization(sc.id, locale.value)
        })
      }
    })
  })

  return {
    title,
    sections
  }
}

export default useArchive